/* App.css */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f0;  /* Light grey background */
  color: #333;  /* Dark text color */
  line-height: 1.6;  /* Improved line spacing for readability */
  overflow-x: hidden;  /* Hide horizontal scroll if any */
}

h1 {
  font-family: 'Georgia', serif;
  font-size: 2.5em;
  color: #2c3e50;
  text-align: center;
  margin: 0;
  padding: 0.5em 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styles for the container wrapping your images and text */
.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: #fff;  /* White background */
  border-radius: 8px;  /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  /* Light shadow for depth */
  overflow: hidden;  /* Hide anything outside the container's bounds */
}

/* ... other styles ... */
