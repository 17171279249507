/* styles.css */
.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2em 0;
}

.img {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Shadow effect */
  border: 2px solid #ddd;
  /* Border */
  transition: transform 0.2s, filter 0.2s;
  /* Transition effects */
  object-fit: cover;
  /* Object fit */
  margin: 20px;
  /* Margin */
  padding: 20px;
  /* Padding */
  filter: grayscale(80%);
  /* Filter effects */
}

.img:hover {
  transform: scale(1.05);
  /* Scale effect on hover */
  filter: grayscale(0%);
  /* Filter effects on hover */
}

.finalText {
  font-family: 'Comic Sans MS', sans-serif;
  font-size: 2.5em;
  color: #e74c3c;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f1c40f;
  border-radius: 10px;
  padding: 0.5em 1em;
  margin: 2em auto 0;
  display: inline-block;
}

/* styles.css */
.animatedImage {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  max-width: 100%;
  /* Ensure images are responsive */
  height: auto;
  border-radius: 15px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Shadow effect */
  border: 2px solid #ddd;
  /* Border */
  object-fit: cover;
  /* Maintain aspect ratio */
  margin: 20px;
  /* Margin */
  padding: 20px;
  /* Padding */
  filter: grayscale(80%);
  /* Grayscale filter */
}

.animatedImage.inView {
  opacity: 1;
  transform: translateY(0);
  filter: grayscale(0%);
  /* Remove grayscale filter when in view */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out, filter 1s ease-in-out;
  /* Smooth transition */
}

.animatedImage:hover {
  transform: scale(1.05);
  /* Scale effect on hover */
  filter: grayscale(0%);
  /* Remove grayscale filter on hover */
}

/* styles.css */
.letter {
  display: inline-block;
  line-height: 1em;
}


@keyframes pulsateHeart {
  0% { transform: translate(-50%, -50%) scale(1); }
  50% { transform: translate(-50%, -50%) scale(1.1); }
  100% { transform: translate(-50%, -50%) scale(1); }
}
@keyframes beat {
  0% { transform: translate(-50%, -50%) scale(2); }
  50% { transform: translate(-50%, -50%) scale(2.2); }
  100% { transform: translate(-50%, -50%) scale(2); }
  
}
/* CSS */
.finalContainer {
  display: flex;
  flex-direction: column;
  margin-left: 50%;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.heart-container {
  position: relative;
  width: 200px;
  height: 180px;
  transform: scale(1.5em);
  animation: beat 1.5s infinite alternate;
}



.heart-container::before,
.heart-container::after {
  content: "";
  position: absolute;
  top: 0;
  width: 52%;
  height: 80%;
  border-radius: 50px 50px 0 0;
  background: #ff6b6b;
  /* Red Color */
}

.heart-container::before {
  left: 50%;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}

.heart-container::after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}

.heart-text {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  font-size: 0.8em;
  color: #fff;
  /* White Color */
  z-index: 1;
  /* This will bring the text above the heart shape */
}

.arrow {
  font-size: 5em;
  animation: pulsate 1.5s infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  /* Center the arrow horizontally and vertically */
}
@keyframes pulsate {
  0% { transform: translate(-50%, -50%) scale(1); opacity: 1; }
  50% { transform: translate(-50%, -40%) scale(1.1); opacity: 0.5; }
  100% { transform: translate(-50%, -50%) scale(1); opacity: 1; }
}

.arrow {
  font-size: 5em;
  animation: pulsate 1.5s infinite;
  position: absolute;
  top: 30%;
  left: 50%;
}

.arrow-container {
  position: relative;
  width: 250px;  /* Adjusted dimensions to fit the heart and arrow */
  height: 250px; /* Adjusted dimensions to fit the heart and arrow */
  margin: auto;  /* Center the container */
  top: 50%;
  transform: translateY(-50%);  /* Center the container vertically */
}

/* ...other styles... */

.arrow-container {
  position: relative;
  width: 250px;
  /* Adjusted dimensions to fit the heart and arrow */
  height: 250px;
  /* Adjusted dimensions to fit the heart and arrow */
  margin: auto;
  /* Center the container */
  top: 50%;
  transform: translateY(-50%);
  /* Center the container vertically */
}
/* ... other styles ... */


.heart {
  position: absolute;
  width: 200px;
  height: 180px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: pulsateHeart 1.5s infinite; /* Added pulsating animation */
}

.heart::before,
.heart::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100px;
  height: 140px;
  border-radius: 100px 100px 0 0;
  background: red;
}

.heart::before {
  left: 100px;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}

.heart::after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}

/* ... other styles ... */
